import { takeEvery, put, call } from 'redux-saga/effects';

// services
import accountService from '../../services/account';

// reducers
import * as accountActions from '../reducers/account';

export function* fetchAccountData({ payload }) {
    try {
        const response = yield call(accountService.fetchAccountData, payload);
        if (response) {
            yield put({ type: accountActions.fetchAccountSuccess.type, response });
        } else {
            yield put({ type: accountActions.fetchAccountError.type, response });
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        yield put({ type: accountActions.fetchAccountError.type, response: error });
    }
}

export function* updateAccountData({ payload }) {
    try {
        const response = yield call(accountService.updateAccountData, payload);
        if (response) {
            yield put({ type: accountActions.updateUserSuccess.type, response });
        } else {
            yield put({ type: accountActions.updateUserError.type, response });
        }
    } catch (error) {
        yield put({ type: accountActions.updateUserError.type, response: error.response });
    }
}

export function* uploadProfilePicture({ payload }) {
    try {
        const response = yield call(accountService.uploadProfilePicture, payload);
        if (response) {
            yield put({ type: accountActions.uploadProfileSuccess.type, response });
        } else {
            yield put({ type: accountActions.uploadProfileError.type, response });
        }
    } catch (error) {
        yield put({ type: accountActions.uploadProfileError.type, response: error.response });
    }
}

export function* vaccinatedData({ payload }) {
    try {
        const response = yield call(accountService.vaccinatedData, payload);
        if (response) {
            yield put({ type: accountActions.vaccinatedDataSuccess.type, response });
        } else {
            yield put({ type: accountActions.vaccinatedDataError.type, response });
        }
    } catch (error) {
        yield put({ type: accountActions.vaccinatedDataError.type, response: error.response });
    }
}

export default function* watchers() {
    yield takeEvery(accountActions.fetchAccount.type, fetchAccountData);
    yield takeEvery(accountActions.updateUser.type, updateAccountData);
    yield takeEvery(accountActions.uploadProfile.type, uploadProfilePicture);
    yield takeEvery(accountActions.vaccinatedData.type, vaccinatedData);
}
