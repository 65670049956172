import React from 'react';
import PropTypes from 'prop-types';
import { ThreeDots } from '@agney/react-loading';

const Loding = props => {
    const { isLoading} = props;
    return isLoading ? (
        <div style={{position:"absolute", top:"50%", left:"45%", color:"#00bfd9"}}>
         <ThreeDots width="50"  />        
        </div>
    ) : null;
};

Loding.propTypes = {
    isLoading: PropTypes.bool, 
};

Loding.defaultProps = {
    isLoading: true,
};

export default Loding;
