import axios from "../helpers/api";

const httpRequests = {
    fetchMedicalHistoryData: (data) => axios.get("/reports/medical-history", data),
    fetchEncounterData: (encounterId, data) => axios.get(`/reports/encounter/${encounterId}`, data),
    fetchAllergyData: (data) => axios.get("/reports/allergies", data),
    fetchDiagnosis: (data) => axios.get("/reports/diagnosis", data),
    fetchNcd: (data) => axios.get("/reports/ncd", data),
    fetchPrescriptions: (data) => axios.get("/reports/prescriptions", data),



};

export default httpRequests;
