import axios from 'axios';
import browserStorage from './browserStorage';
import config from '../config';

const axiosInstance = axios.create({
    baseURL: `${config.api_base_url}/${config.api_prefix}`,
});

axiosInstance.interceptors.request.use(
    config => {
        config.headers.Authorization = `Bearer ${browserStorage.getLocalStorage('token')}`;
        return config;
    },
    error => {
        Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // const { status } = error.response;
        // if (status === 401 || status === 409) {
        //     removeLocalStorage('token');
        // }
        return Promise.reject(error);
    },
);

export default axiosInstance;
