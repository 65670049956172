import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import LoadingComponent from './app/common/loding';
import { useContext } from 'react';
// import { ApplicationAuth } from './app/common/authRoute';

// context
import AuthContext from "./app/contexts/authContext";

const SignIn = Loadable({
    loader: () => import('./app/components/home'),
    loading: LoadingComponent
});

const InnerPage = Loadable({
    loader: () => import('./app/components/innerPage'),
    loading: LoadingComponent
});

const PasswordResetPage = Loadable({
    loader: () => import('./app/components/passwordResetPage'),
    loading: LoadingComponent
});

const UpcomingAppointments = Loadable({
    loader: () => import('./app/containers/innerBody'),
    loading: LoadingComponent
});

const Inner = Loadable({
    loader: () => import('./app/containers/innerNav'),
    loading: LoadingComponent
});


const Routes = () => {

    const { authData } = useContext(AuthContext);
    return (
        <div className="body-wrapper">
            <Router>
                <Switch>
                    <Route exact path="/">
                        <SignIn />
                    </Route>
                    <Route exact path="/reset-password/:token">
                        <PasswordResetPage />
                    </Route>
                    {authData.token ? <Route exact path="/innerPage">
                        <InnerPage />
                    </Route> : <Redirect to="/" />}
                    {authData.token ? <Route exact path="/innerPage/upcomingAppointments">
                        <InnerPage />
                    </Route> : <Redirect to="/" />}
                    {/*<Route exact path="/signup">
                        <SignUp />
                    </Route>
                    <ApplicationAuth>
                        <Route exact path="/batch">
                            <Navigation />
                            <AddBatch />
                        </Route>
                    </ApplicationAuth> */}
                </Switch>
            </Router>
        </div>
    );
}

export default Routes;