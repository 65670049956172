import { createSlice } from '@reduxjs/toolkit';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
    loginData: {
        loading: false,
        data: null,
        error: null,
    },
    resetPasswordData: {
        loading: false,
        data: null,
        error: null,
    }, 
   resendOTPData: {
        loading: false,
        data: null,
        error: null,
    },
};

const createdSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginUser(state, { payload }) {
            return {
                ...state,
                loginData: { ...state.loginData, loading: true },
            };
        },
        loginUserSuccess(state, { response }) {
            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    loading: false,
                    data: response.data,
                    error: null,
                },
            };
        },
        loginUserError(state, { response }) {
            return {
                ...state,
                loginData: {
                    ...state.loginData,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        loginUserReset(state, { payload }) {
            return {
                ...state,
                loginData: initialState.loginData,
            };
        },
        logoutUser(state, { payload }) {
            return {
                ...state,
            };
        },
        resetPassword(state, { payload }) {
            return {
                ...state,
                resetPasswordData: { ...state.resetPasswordData, loading: true },
            };
        },
        resetPasswordSuccess(state, { response }) {
            return {
                ...state,
                resetPasswordData: {
                    ...state.resetPasswordData,
                    loading: false,
                    data: response.data,
                    error: null,
                },
            };
        },
        resetPasswordError(state, { response }) {
            return {
                ...state,
                resetPasswordData: {
                    ...state.resetPasswordData,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        resetPasswordReset(state, { payload }) {
            return {
                ...state,
                resetPasswordData: initialState.resetPasswordData,
            };
        },
        reSendOTP(state, { payload }) {
            return {
                ...state,
                resendOTPData: initialState.resendOTPData,
            };
        },
        reSendOTPSuccess(state, { response }) {
            return {
                ...state,
                resendOTPData: {
                    ...state.resendOTPData,
                    loading: false,
                    data: response.data,
                    error: null,
                },
            };
        },
        reSendOTPError(state, { response }) {
            return {
                ...state,
                resendOTPData: {
                    ...state.resendOTPData,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        reSendOTPReset(state, { payload }) {
            return {
                ...state,
                resendOTPData: initialState.resendOTPData,
            };
        },
    },
});

const { actions, reducer } = createdSlice;

export const {
    loginUser,
    loginUserSuccess,
    loginUserError,
    loginUserReset,
    logoutUser,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordError,
    resetPasswordReset,
    reSendOTP,
    reSendOTPSuccess,
    reSendOTPError,
    reSendOTPReset
} = actions;

export default reducer;
