import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';


// services
import bookingService from '../../services/booking';

// reducers
import * as actions from '../reducers/booking';

export function* clinicBooking({ payload }) {
    try {
        const response = yield call(bookingService.addClinicBooking, payload);
        if (response) {
            yield put({ type: actions.clinicBookingSuccess.type, response });
        } else {
            yield put({ type: actions.clinicBookingError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.clinicBookingError.type, response: error.response });
    }
}

export function* teleMedBooking({ payload }) {
    try {
        const response = yield call(bookingService.addTeleMedBooking, payload);
        if (response) {
            yield put({ type: actions.teleMedBookingSuccess.type, response });
        } else {
            yield put({ type: actions.teleMedBookingError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.teleMedBookingError.type, response: error.response });
    }
}

export function* fetchUserAppointment({ payload }) {
    try {
        const response = yield call(bookingService.fetchBooking, payload);
        if (response) {
            yield put({ type: actions.fetchUserAppointmentSuccess.type, response });
        } else {
            yield put({ type: actions.fetchUserAppointmentError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.fetchUserAppointmentError.type, response: error.response });
    }
}

export function* removeAppointment({ payload }) {
    try {
        const response = yield call(bookingService.removeAppointment, payload);
        if (response) {
            yield put({ type: actions.removeAppointmentSuccess.type, response });
        } else {
            yield put({ type: actions.removeAppointmentError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.removeAppointmentError.type, response: error.response });
    }
}

export function* fetchAllAppointmentCounts({ payload }) {
    try {
        const response = yield call(bookingService.fetchAppointmentCounts, payload);
        if (response) {
            yield put({ type: actions.allAppointmentCountsSuccess.type, response });
        } else {
            yield put({ type: actions.allAppointmentCountsError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.allAppointmentCountsError.type, response: error.response });
    }
}



export default function* watchers() {
    yield takeEvery(actions.clinicBooking.type, clinicBooking);
    yield takeEvery(actions.teleMedBooking.type, teleMedBooking);
    yield takeEvery(actions.fetchUserAppointment.type, fetchUserAppointment);
    yield takeEvery(actions.removeAppointment.type, removeAppointment);
    yield takeEvery(actions.allAppointmentCountsLoading.type, fetchAllAppointmentCounts);

}
