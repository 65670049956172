import { createSlice } from '@reduxjs/toolkit';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
    selectedAccount: {
        loading: false,
        data: null,
        error: null,
    },
    updateData: {
        loading: false,
        data: null,
        error: null,
    },
    countryList: {
        loading: false,
        data: null,
        error: null,
    },
    profilePic: {
        loading: false,
        data: null,
        error: null,
    }, 
     userVaccinationData: {
        loading: false,
        data: null,
        error: null,
    },
};

const createdSlice = createSlice({
    name: 'accountDetails',
    initialState,
    reducers: {
        fetchAccount(state, { payload }) {
            return {
                ...state,
                selectedAccount: { ...state.selectedAccount, loading: true },
            };
        },
        fetchAccountSuccess(state, { response }) {
            return {
                ...state,
                selectedAccount: {
                    ...state.selectedAccount,
                    loading: false,
                    data: response.data ? response.data.body : response.data,
                    error: null,
                },
            };
        },
        fetchAccountError(state, { response }) {
            return {
                ...state,
                selectedAccount: {
                    ...state.selectedAccount,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },

        updateUser(state, { payload }) {
            return {
                ...state,
                updateData: { ...state.updateData, loading: true },
            };
        },
        updateUserSuccess(state, { response }) {
            return {
                ...state,
                updateData: {
                    ...state.updateData,
                    loading: false,
                    data: response.data,
                    error: null,
                },
            };
        },
        updateUserError(state, { response }) {
            return {
                ...state,
                updateData: {
                    ...state.updateData,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        updateUserReset(state, { response }) {
            return {
                ...state,
                updateData: initialState.updateData,
            };
        },
        uploadProfile(state, { payload }) {
            return {
                ...state,
                profilePic: { ...state.profilePic, loading: true },
            };
        },
        uploadProfileSuccess(state, { response }) {
            return {
                ...state,
                profilePic: {
                    ...state.profilePic,
                    loading: false,
                    data: response.data ? response.data : response.data,
                    error: null,
                },
            };
        },
        uploadProfileError(state, { response }) {
            return {
                ...state,
                profilePic: {
                    ...state.profilePic,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        uploadProfileReset(state, { response }) {
            return {
                ...state,
                profilePic: initialState.profilePic,
            };
        },
        vaccinatedData(state, { payload }) {
            return {
                ...state,
                userVaccinationData: { ...state.userVaccinationData, loading: true },
            };
        },
        vaccinatedDataSuccess(state, { response }) {
            return {
                ...state,
                userVaccinationData: {
                    ...state.userVaccinationData,
                    loading: false,
                    data: response.data ? response.data : response.data,
                    error: null,
                },
            };
        },
        vaccinatedDataError(state, { response }) {
            return {
                ...state,
                userVaccinationData: {
                    ...state.userVaccinationData,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        vaccinatedDataReset(state, { response }) {
            return {
                ...state,
                userVaccinationData: initialState.userVaccinationData,
            };
        },
        
    },
});

const { actions, reducer } = createdSlice;

export const {
    fetchAccount,
    fetchAccountSuccess,
    fetchAccountError,
    updateUser,
    updateUserSuccess,
    updateUserError,
    updateUserReset,
    uploadProfile,
    uploadProfileSuccess,
    uploadProfileError,
    uploadProfileReset,
    vaccinatedData,
    vaccinatedDataSuccess,
    vaccinatedDataError,
    vaccinatedDataReset
} = actions;

export default reducer;
