// reducer imports
import register from "./register";
import verifySms from "./verifySms";
import login from "./login";
import booking from './booking'
import account from './account'
import myReports from './myReports'
import forgotPassword from './forgotPassword'
import vitalSigns from './vitalSigns'

const rootReducer = {
  register,
  verifySms,
  login,
  booking,
  account,
  myReports,
  forgotPassword,
  vitalSigns
};

export default rootReducer;
