import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';


// services
import myReportService from '../../services/myReportService';

// reducers
import * as actions from '../reducers/myReports';

export function* medicalHistory() {
    console.log('running medical history saga')
    try {
        const response = yield call(myReportService.fetchMedicalHistoryData);
        if (response) {
            yield put({ type: actions.medicalHistorySuccess.type, response });
        } else {
            yield put({ type: actions.medicalHistoryError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.medicalHistoryError.type, response: error.response });
    }
}

export function* encounterData({ payload }) {
    console.log('running encounter data saga')
    try {
        const response = yield call(myReportService.fetchEncounterData, payload);
        if (response) {
            yield put({ type: actions.encounterSuccess.type, response });
        } else {
            yield put({ type: actions.encounterError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.encounterError.type, response: error.response });
    }
}

export function* allergyData({ payload }) {
    try {
        const response = yield call(myReportService.fetchAllergyData, payload);
        if (response) {
            yield put({ type: actions.allergySuccess.type, response });
        } else {
            yield put({ type: actions.allergyError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.allergyError.type, response: error.response });
    }
}

export function* diagnosisData({ payload }) {
    try {
        const response = yield call(myReportService.fetchDiagnosis, payload);
        if (response) {
            yield put({ type: actions.diagnosisSuccess.type, response });
        } else {
            yield put({ type: actions.diagnosisError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.diagnosisError.type, response: error.response });
    }
}

export function* ncdData({ payload }) {
    try {
        const response = yield call(myReportService.fetchNcd, payload);
        if (response) {
            yield put({ type: actions.ncdSuccess.type, response });
        } else {
            yield put({ type: actions.ncdError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.ncdError.type, response: error.response });
    }
}


export function* prescriptionData({ payload }) {
    try {
        const response = yield call(myReportService.fetchPrescriptions, payload);
        if (response) {
            yield put({ type: actions.prescriptionSuccess.type, response });
        } else {
            yield put({ type: actions.prescriptionError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.prescriptionError.type, response: error.response });
    }
}



export default function* watchers() {
    yield takeLatest(actions.medicalHistoryLoading.type, medicalHistory);
    yield takeLatest(actions.encounterLoading.type, encounterData);
    yield takeLatest(actions.diagnosisLoading.type, diagnosisData);
    yield takeLatest(actions.allergyLoading.type, allergyData);
    yield takeLatest(actions.ncdLoading.type, ncdData);
    yield takeLatest(actions.prescriptionLoading.type, prescriptionData);


}
