import { fork } from "redux-saga/effects";
import authSaga from "./authSaga";
import bookingSaga from './bookingSaga';
import accountSaga from './accountSaga'
import myReportSaga from './myReportSaga'
import forgotPasswordSaga from './forgotPasswordSaga'
import vitalSignSaga from './vitalSignSaga'

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(bookingSaga);
  yield fork(accountSaga);
  yield fork(myReportSaga);
  yield fork(forgotPasswordSaga);
  yield fork(vitalSignSaga);
}
