import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';


// services
import vitalSignService from '../../services/vitalSigns';

// reducers
import * as actions from '../reducers/vitalSigns';

export function* vitalSignsData() {
    try {
        const response = yield call(vitalSignService.fetchVitalSignData);
        if (response) {
            yield put({ type: actions.vitalSignsSuccess.type, response });
        } else {
            yield put({ type: actions.vitalSignsError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.vitalSignsError.type, response: error.response });
    }
}


export default function* watchers() {
    yield takeLatest(actions.vitalSignsLoading.type, vitalSignsData);

}
