import { createSlice, createSelector } from '@reduxjs/toolkit';

export const VITAL_NAMES = {
    PULSE_RATE: { displayName: 'Pulse', apiName: 'Pulse Rate' },
    BLOOD_PRESSURE: { displayName: 'Pressure', apiName: 'Blood Pressure' },
    Temperature: { displayName: 'Temp', apiName: 'Temperature' },
    RESPIRATORY_RATE: { displayName: 'Respiratory', apiName: 'Respiratory Rate' },
    OXYGEN_SATURATION: { displayName: 'Sp O2', apiName: 'Oxygen Saturation' },
    HEIGHT: { displayName: 'Height', apiName: 'Height' },
    WEIGHT: { displayName: 'Weight', apiName: 'Weight' },
    BMI: { displayName: 'BMI', apiName: 'BMI' }
}

export const VITAL_SIGN_MEASUREMENT_NAMES = [
    {
        key: VITAL_NAMES.BLOOD_PRESSURE.displayName,
        names: ['Diastolic', 'Systolic']
    }
]

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
    vitalSignData: {
        loading: false,
        data: null,
        error: null,
    }
};

const createdSlice = createSlice({
    name: 'vitalSigns',
    initialState,
    reducers: {
        vitalSignsLoading(state, { payload }) {
            state.vitalSignData.loading = true;
        },
        vitalSignsSuccess(state, { response }) {
            state.vitalSignData.data = response.data.body;
            state.vitalSignData.loading = false;
        },
        vitalSignsError(state, { response }) {
            state.vitalSignData.error = response ? iff(response.data, response.data.message, response.data) : null
            state.vitalSignData.loading = false;

        },
        vitalSignsReset(state, { payload }) {

            state.vitalSignData = initialState.vitalSignData
        },

    },
});

const { actions, reducer } = createdSlice;

export const vitalSignSelector = (vitalSignName) => createSelector(
    state => state.vitalSigns.vitalSignData,
    vitalSignData => vitalSignData.data ? vitalSignData.data
        .map(d => ({
            year: d.year,
            vitals: d.vitals.filter(v => v.vitalName === vitalSignName).slice(0, 10)
        }))
        : null
)

export const vitalSignErrorSelector = () => createSelector(
    state => state.vitalSigns.vitalSignData,
    vitalSignData => vitalSignData.error
)

export const vitalSignLogingSelector = () => createSelector(
    state => state.vitalSigns.vitalSignData,
    vitalSignData => vitalSignData.loading
)

export const {
    vitalSignsError,
    vitalSignsLoading,
    vitalSignsSuccess,
    vitalSignsReset
} = actions;

export default reducer;
