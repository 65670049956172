import axios from "../helpers/api";

const httpRequests = {
    requestReset: (data) => axios.post("/users/forgot-password/email", data),
    requestResetOtp: (data) => axios.post("/users/forgot-password/mobile", data),
    verifyToken: (token, data) => axios.post(`/users/forgot-password/verify-token/${token}`, data),
    verifyOtp: (data) => axios.post(`/users/forgot-password/verify-otp/`, data),
    resetPwd: (data) => axios.post(`/users/forgot-password/reset-pwd`, data),
    verifyHealthId: (data) => axios.post(`/users/verify-healthid/`, data),
};

export default httpRequests;
