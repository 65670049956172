import { createSelector, createSlice } from '@reduxjs/toolkit';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
    clinicBookingData: {
        loading: false,
        data: null,
        error: null,
    },
    teleMedicineBookingData: {
        loading: false,
        data: null,
        error: null,
    },
    userAppointment: {
        loading: false,
        data: null,
        error: null,
    },
    removeReservation: {
        loading: false,
        data: null,
        error: null,
    },
    allAppointmentCounts: {
        loading: false,
        totalAppointments: null,
        todayAppointments: null,
        error: false
    }
};

const createdSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        clinicBooking(state, { payload }) {
            return {
                ...state,
                clinicBookingData: { ...state.clinicBookingData, loading: true },
            };
        },
        clinicBookingSuccess(state, { response }) {
            return {
                ...state,
                clinicBookingData: {
                    ...state.clinicBookingData,
                    loading: false,
                    data: response.data,
                    error: null,
                },
            };
        },
        clinicBookingError(state, { response }) {
            return {
                ...state,
                clinicBookingData: {
                    ...state.clinicBookingData,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        clinicBookingReset(state, { payload }) {
            return {
                ...state,
                clinicBookingData: initialState.clinicBookingData,
            };
        },
        teleMedBooking(state, { payload }) {
            return {
                ...state,
                teleMedicineBookingData: { ...state.clinicBookingData, loading: true },
            };
        },
        teleMedBookingSuccess(state, { response }) {
            return {
                ...state,
                teleMedicineBookingData: {
                    ...state.clinicBookingData,
                    loading: false,
                    data: response.data,
                    error: null,
                },
            };
        },
        teleMedBookingError(state, { response }) {
            return {
                ...state,
                teleMedicineBookingData: {
                    ...state.clinicBookingData,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        teleMedBookingReset(state, { payload }) {
            return {
                ...state,
                teleMedicineBookingData: initialState.clinicBookingData,
            };
        },
        fetchUserAppointment(state, { payload }) {
            return {
                ...state,
                userAppointment: { ...state.userAppointment, loading: true },
            };
        },
        fetchUserAppointmentSuccess(state, { response }) {
            return {
                ...state,
                userAppointment: {
                    ...state.userAppointment,
                    loading: false,
                    data: response.data ? response.data.body : response.data,
                    error: null,
                },
            };
        },
        fetchUserAppointmentError(state, { response }) {
            return {
                ...state,
                userAppointment: {
                    ...state.userAppointment,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        removeAppointment(state, { payload }) {
            return {
                ...state,
                removeReservation: { ...state.removeReservation, loading: true },
            };
        },
        removeAppointmentSuccess(state, { response }) {
            return {
                ...state,
                removeReservation: {
                    ...state.removeReservation,
                    loading: false,
                    data: response.data ? response.data.body : response.data,
                    error: null,
                },
            };
        },
        removeAppointmentError(state, { response }) {
            return {
                ...state,
                removeReservation: {
                    ...state.removeReservation,
                    loading: false,
                    data: null,
                    error: response ? iff(response.data, response.data.message, response.data) : null,
                },
            };
        },
        allAppointmentCountsLoading(state, { response }) {
            state.allAppointmentCounts.loading = true
        },
        allAppointmentCountsSuccess(state, { response }) {
            state.allAppointmentCounts.loading = false;
            state.allAppointmentCounts.totalAppointments = response.data.body.totalAppointmentCount;
            state.allAppointmentCounts.todayAppointments = response.data.body.todaysAppointmentCount;
        },
        allAppointmentCountsError(state, { response }) {
            state.allAppointmentCounts.loading = false;
            state.allAppointmentCounts.error = response ? iff(response.data, response.data.message, response.data) : null;
        },
    },
});

const { actions, reducer } = createdSlice;

export const allAppointmentCountSelector = () => createSelector(
    state => state.booking.allAppointmentCounts,
    counts => ({ totalCount: counts.totalAppointments, todayCount: counts.todayAppointments })
)

export const {
    clinicBooking,
    clinicBookingSuccess,
    clinicBookingError,
    clinicBookingReset,
    teleMedBooking,
    teleMedBookingSuccess,
    teleMedBookingError,
    teleMedBookingReset,
    fetchUserAppointment,
    fetchUserAppointmentSuccess,
    fetchUserAppointmentError,
    removeAppointment,
    removeAppointmentSuccess,
    removeAppointmentError,
    allAppointmentCountsError,
    allAppointmentCountsLoading,
    allAppointmentCountsSuccess
} = actions;

export default reducer;
