import { createSlice, createSelector } from '@reduxjs/toolkit';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
    passwordResetData: {
        loading: false,
        healthId: {
            id: null,
            valid: false,
            error: false,
            loading: false,
        },
        emailAvailable: false,
        error: null,
        displayForgotPasswordForm: false,
        redirectUrl: null,
        verifyEmail: {
            loading: false,
            success: false,
            error: null
        },
        token: {
            loading: false,
            valid: false,
            error: false
        },
        otpRequest: {
            loading: false,
            success: false,
            error: false
        },
        verifyOtp: {
            loading: false,
            valid: false,
            error: false
        },
        reset: {
            loading: false,
            success: false,
            error: null,
            data: null,
        }
    },
};

const createdSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        passwordResetLoading(state, { payload }) {
            state.passwordResetData.reset.loading = true;
        },
        passwordResetSuccess(state, { response }) {
            state.passwordResetData.reset.success = true;
            state.passwordResetData.reset.loading = false;
            state.passwordResetData.reset.data = response.data;
        },
        passwordResetError(state, { response }) {
            state.passwordResetData.reset.loading = false;
            state.passwordResetData.reset.error = response ? iff(response.data, response.data.message, response.data) : null
        },
        passwordResetReset(state, { payload }) {

            state.passwordResetData.reset = initialState.passwordResetData.reset
        },
        passwordResetDisplayForm(state, { payload }) {
            state.passwordResetData.healthId.id = payload.healthId;
            state.passwordResetData.displayForgotPasswordForm = true
        },
        passwordResetEmailLoading(state, { payload }) {
            state.passwordResetData.verifyEmail.loading = true
        },
        passwordResetEmailSent(state, { payload }) {
            state.passwordResetData.verifyEmail.success = true
            state.passwordResetData.verifyEmail.loading = false

        },
        passwordResetEmailError(state, { response }) {
            state.passwordResetData.verifyEmail.error = response ? iff(response.data, response.data.message, response.data) : null
            state.passwordResetData.verifyEmail.loading = false

        },
        passwordResetTokenLoading(state, { payload }) {
            state.passwordResetData.token.loading = true
        },
        passwordResetTokenValid(state, { payload }) {
            state.passwordResetData.token.valid = true
            state.passwordResetData.token.loading = false
        },
        passwordReserTokenInvalid(state, { payload }) {
            state.passwordResetData.token.error = true
            state.passwordResetData.token.loading = false
        },
        passwordResetRequestOTPLoading(state, { payload }) {
            state.passwordResetData.otpRequest.loading = true
        },
        passwordResetRequestOTPSuccess(state, { payload }) {
            state.passwordResetData.otpRequest.success = true
            state.passwordResetData.otpRequest.loading = false
        },
        passwordResetRequestOTPError(state, { payload }) {
            state.passwordResetData.otpRequest.error = true
            state.passwordResetData.otpRequest.loading = false
        },
        passwordResetVerifyOTPLoading(state, { payload }) {
            state.passwordResetData.verifyOtp.loading = true
        },
        passwordResetVerifyOTPSuccess(state, { response }) {
            state.passwordResetData.verifyOtp.valid = true
            state.passwordResetData.verifyOtp.loading = false
            state.passwordResetData.redirectUrl = response.data.body.redirectUrl
        },
        passwordResetVerifytOTPError(state, { payload }) {
            state.passwordResetData.verifyOtp.error = true
            state.passwordResetData.verifyOtp.loading = false
        },
        passwordResetVerifyHealthIDLoading(state, { payload }) {
            state.passwordResetData.healthId.loading = true
        },
        passwordResetVerifyHealthIdSuccess(state, { response }) {
            state.passwordResetData.healthId.valid = true
            state.passwordResetData.healthId.loading = false
        },
        passwordResetVerifytHealthIdError(state, { payload }) {
            state.passwordResetData.healthId.error = true
            state.passwordResetData.healthId.loading = false
        },
        passwordResetEmailAvailable(state, { payload }) {
            state.passwordResetData.emailAvailable = true
        },
    },
});


export const healthIdSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.healthId.id
)

export const healthIdValidSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.healthId.valid
)
export const healthIdLoadingSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.healthId.loading
)
export const healthIdErrorSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.healthId.error
)

export const isEmailAvailable = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.emailAvailable
)

export const verifyEmailLoadingSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.verifyEmail.loading
)

export const verifyEmailSuccessSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.verifyEmail.success
)

export const verifyEmailErrorSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.verifyEmail.error
)

export const verifyTokenValidSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.token.valid
)

export const verifyTokenLoadingSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.token.loading
)

export const verifyTokenErrorSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.token.error
)

export const resetSuccessSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.reset.success
)

export const resetLoadingSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.reset.loading
)

export const resetErrorSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.reset.error
)


export const otpRequestSuccessSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.otpRequest.success
)

export const optRequestLoadingSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.otpRequest.loading
)

export const optRequestErrorSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.otpRequest.error
)


export const otpVerifySuccessSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.verifyOtp.valid
)

export const otpVerifyLoadingSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.verifyOtp.loading
)

export const otpVerifyErrorSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.verifyOtp.error
)

export const passwordResetRedirectUrlSelector = () => createSelector(
    state => state.forgotPassword.passwordResetData,
    passwordResetData => passwordResetData.redirectUrl
)



const { actions, reducer } = createdSlice;

export const {
    passwordReserTokenInvalid,
    passwordResetDisplayForm,
    passwordResetEmailSent,
    passwordResetError,
    passwordResetLoading,
    passwordResetReset,
    passwordResetSuccess,
    passwordResetTokenLoading,
    passwordResetTokenValid,
    passwordResetEmailLoading,
    passwordResetEmailError,
    passwordResetVerifyOTPLoading,
    passwordResetRequestOTPError,
    passwordResetRequestOTPLoading,
    passwordResetRequestOTPSuccess,
    passwordResetVerifyOTPSuccess,
    passwordResetVerifytOTPError,
    passwordResetEmailAvailable,
    passwordResetVerifyHealthIDLoading,
    passwordResetVerifyHealthIdSuccess,
    passwordResetVerifytHealthIdError
} = actions;

export default reducer;
