import dev from './dev';
import pre_qa from './pre-qa';
import stg from './stg';

const getConfigBasedOnEnv = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'dev':
            return dev;
        case 'pre-qa':
            return pre_qa;
        case 'stg':
            return stg;
        default:
            return dev;
    }
};

const config = {
    ...getConfigBasedOnEnv(),
}

export default config;