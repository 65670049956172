import axios from "../helpers/api";

const httpRequests = {
    addClinicBooking: (data) => axios.post("/reservations/clinic-booking", data),
    addTeleMedBooking: (data) => axios.post("/reservations/TeleMed-booking", data),
    fetchBooking: (data) => axios.get("/reservations/reservation-list", data),
    removeAppointment: (data) => axios.post("/reservations/remove-booking", data),
    fetchAppointmentCounts: (data) => axios.get("/reports/appointment-count", data),
};

export default httpRequests;
