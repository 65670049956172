import React from "react";
import ReactDOM from "react-dom";
import "./app/assets/styles/main.scss";
// import App from "./App";
// import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { Provider } from "react-redux";
import configureStore from "./app/store";

import { ScProvider } from "./app/contexts/scContext";
import { AuthProvider } from "./app/contexts/authContext";

//store
// import configureStore from "./app/store/index";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ScProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AuthProvider>
    </ScProvider>
  </Provider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
