import { createSlice, createSelector } from '@reduxjs/toolkit';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
    medicalHistoryData: {
        loading: false,
        data: null,
        error: null,
    },
    encounterData: {
        loading: false,
        data: [],
        error: null
    },
    diagnosisData: {
        loading: false,
        data: null,
        error: null
    },
    allergyData: {
        loading: false,
        data: null,
        error: null
    },
    ncdData: {
        loading: false,
        data: null,
        error: null
    },
    prescriptionsData: {
        loading: false,
        data: null,
        error: null
    }
};

const createdSlice = createSlice({
    name: 'myReports',
    initialState,
    reducers: {
        medicalHistoryLoading(state, { payload }) {
            state.medicalHistoryData.loading = true;
        },
        medicalHistorySuccess(state, { response }) {
            state.medicalHistoryData.data = response.data.body;
            state.medicalHistoryData.loading = false;
        },
        medicalHistoryError(state, { response }) {
            state.medicalHistoryData.loading = false;
            state.medicalHistoryData.error = response ? iff(response.data, response.data.message, response.data) : null
        },
        medicalHistoryReset(state, { payload }) {

            state.medicalHistoryData = initialState.medicalHistoryData
        },
        encounterLoading(state, { payload }) {
            state.encounterData.loading = true;
        },
        encounterSuccess(state, { response }) {
            state.encounterData.data.push(response.data.body);
            state.medicalHistoryData.loading = false;
        },
        encounterError(state, { response }) {
            state.encounterData.loading = false;
            state.encounterData.error = response ? iff(response.data, response.data.message, response.data) : null
        },
        encounterReset(state, { payload }) {

            state.encounterData = initialState.encounterData
        },
        diagnosisLoading(state, { payload }) {
            state.diagnosisData.loading = true;
        },
        diagnosisSuccess(state, { response }) {
            state.diagnosisData.data = response.data.body.diagnosisList;
            state.diagnosisData.loading = false;
        },
        diagnosisError(state, { response }) {
            state.diagnosisData.loading = false;
            state.diagnosisData.error = response ? iff(response.data, response.data.message, response.data) : null
        },
        diagnosisReset(state, { payload }) {

            state.diagnosisData = initialState.diagnosisData
        },
        allergyLoading(state, { payload }) {
            state.allergyData.loading = true;
        },
        allergySuccess(state, { response }) {
            state.allergyData.data = response.data.body;
            state.allergyData.loading = false;
        },
        allergyError(state, { response }) {
            state.allergyData.loading = false;
            state.allergyData.error = response ? iff(response.data, response.data.message, response.data) : null
        },
        allergyReset(state, { payload }) {

            state.allergyData = initialState.diagnosisData
        },
        ncdLoading(state, { payload }) {
            state.ncdData.loading = true;
        },
        ncdSuccess(state, { response }) {
            state.ncdData.data = response.data.body;
            state.ncdData.loading = false;
        },
        ncdError(state, { response }) {
            state.ncdData.loading = false;
            state.ncdData.error = response ? iff(response.data, response.data.message, response.data) : null
        },
        ncdReset(state, { payload }) {

            state.ncdData = initialState.ncdData
        },
        prescriptionLoading(state, { payload }) {
            state.prescriptionsData.loading = true;
        },
        prescriptionSuccess(state, { response }) {
            state.prescriptionsData.data = response.data.body.prescriptions;
            state.prescriptionsData.loading = false;
        },
        prescriptionError(state, { response }) {
            state.prescriptionsData.loading = false;
            state.prescriptionsData.error = response ? iff(response.data, response.data.message, response.data) : null
        },
        prescriptionReset(state, { payload }) {

            state.prescriptionsData = initialState.ncdData
        },
    },
});

const { actions, reducer } = createdSlice;

export const encounterSelector = (encounterId) => createSelector(
    state => state.myReports.encounterData.data,
    encounterData => encounterData.find(e => e.encounterId === encounterId)
)

export const diagnosisSelector = () => createSelector(
    state => state.myReports.diagnosisData,
    diagnosisData => diagnosisData.data
)

export const diagnosisLoadingSelector = () => createSelector(
    state => state.myReports.diagnosisData,
    diagnosisData => diagnosisData.loading
)


export const diagnosisErrorSelector = () => createSelector(
    state => state.myReports.diagnosisData,
    diagnosisData => diagnosisData.error
)

export const allergySelector = () => createSelector(
    state => state.myReports.allergyData,
    allergyData => allergyData.data
)

export const allergyLoadingSelector = () => createSelector(
    state => state.myReports.allergyData,
    allergyData => allergyData.loading
)


export const allergyErrorSelector = () => createSelector(
    state => state.myReports.allergyData,
    allergyData => allergyData.error
)


export const ncdSelector = () => createSelector(
    state => state.myReports.ncdData,
    ncd => ncd.data
)

export const ncdLoadingSelector = () => createSelector(
    state => state.myReports.ncdData,
    ncd => ncd.loading
)


export const ncdErrorSelector = () => createSelector(
    state => state.myReports.ncdData,
    ncd => ncd.error
)


export const prescriptionSelector = () => createSelector(
    state => state.myReports.prescriptionsData,
    prescriptionsData => prescriptionsData.data
)

export const prescriptionLoadingSelector = () => createSelector(
    state => state.myReports.prescriptionsData,
    prescriptionsData => prescriptionsData.loading
)


export const prescriptionErrorSelector = () => createSelector(
    state => state.myReports.prescriptionsData,
    prescriptionsData => prescriptionsData.error
)

export const {
    medicalHistoryLoading,
    medicalHistorySuccess,
    medicalHistoryError,
    medicalHistoryReset,
    encounterError,
    encounterLoading,
    encounterReset,
    encounterSuccess,
    allergyError,
    allergyLoading,
    allergyReset,
    allergySuccess,
    diagnosisError,
    diagnosisLoading,
    diagnosisReset,
    diagnosisSuccess,
    ncdError,
    ncdLoading,
    ncdReset,
    ncdSuccess,
    prescriptionError,
    prescriptionLoading,
    prescriptionReset,
    prescriptionSuccess
} = actions;

export default reducer;
