import axios from "../helpers/api";

const httpRequests = {
  registerUser: (data) => axios.post("/users/register", data),
  verifySms: (data) => axios.post("/users/verify", data),
  resendOTP: (data) => axios.get(`/users/resendOTP/${data.healthId}/${data.data.is_active}`),
  loginUser: data => axios.post("/users/sign-in", data),
  resetPassword: data => axios.post("/users/reset-pwd", data),
};

export default httpRequests;
