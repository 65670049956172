import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import browserStorage from '../helpers/browserStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState({
        token: browserStorage.getLocalStorage('token') || '',
        user: browserStorage.getLocalStorage('user') || '',
        name: browserStorage.getLocalStorage('name') || '',
        language: browserStorage.getLocalStorage('language') || '',
    });

    // useEffect(() => {
    //     setAuthData({
    //         token: browserStorage.getLocalStorage('token') || '',
    //         user: browserStorage.getLocalStorage('user') || '',
    //     });
    // }, []);

    const values = {
        authData,
        setAuthData: data => {
            if (data.token) browserStorage.setLocalStorage('token', data.token);
            if (data.user) browserStorage.setLocalStorage('user', data.user);
            if (data.name) browserStorage.setLocalStorage('name', data.name);
            if (data.language) browserStorage.setLocalStorage('language', data.language);
            setAuthData({ ...authData, ...data });
        },
        logout: () => {
            browserStorage.removeLocalStorage('token');
            browserStorage.removeLocalStorage('user');
            browserStorage.removeLocalStorage('name');
            browserStorage.removeLocalStorage('language');
            // setAuthData({ token: '', user: '', name: '', language: ''});
        },
    };

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
