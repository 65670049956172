import { createSlice } from "@reduxjs/toolkit";

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
  registerData: {
    loading: false,
    data: null,
    error: null,
  },
};

const createdSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    registerUser(state, { payload }) {
      return {
        ...state,
        registerData: { ...state.registerData, loading: true },
      };
    },
    registerUserSuccess(state, { response }) {
      return {
        ...state,
        registerData: {
          ...state.registerData,
          loading: false,
          data: response,
          error: null,
        },
      };
    },
    registerUserError(state, { response }) {
      return {
        ...state,
        registerData: {
          ...state.registerData,
          loading: false,
          data: null,
          error: response
            ? iff(response.data, response.data.message, response.data)
            : null,
        },
      };
    },
    registerUserReset(state, { response }) {
      return {
        ...state,
        registerData: initialState.registerData,
      };
    },
    logoutUser(state, { payload }) {
      return {
        ...state,
      };
    },
  },
});

const { actions, reducer } = createdSlice;

export const {
  registerUser,
  registerUserSuccess,
  registerUserError,
  registerUserReset,
  logoutUser,
} = actions;

export default reducer;
