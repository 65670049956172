import { createSlice } from "@reduxjs/toolkit";

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const initialState = {
  verifyData: {
    loading: false,
    data: null,
    error: null,
  },
};

const createdSlice = createSlice({
  name: "verifySms",
  initialState,
  reducers: {
    verifySms(state, { payload }) {
      return {
        ...state,
        verifyData: { ...state.verifyData, loading: true },
      };
    },
    verifySmsSuccess(state, { response }) {
      return {
        ...state,
        verifyData: {
          ...state.verifyData,
          loading: false,
          data: response.data,
          error: null,
        },
      };
    },
    verifySmsError(state, { response }) {
      return {
        ...state,
        verifyData: {
          ...state.verifyData,
          loading: false,
          data: null,
          error: response
            ? iff(response.data, response.data.message, response.data)
            : null,
        },
      };
    },
    verifySmsReset(state, { payload }) {
      return {
        ...state,
        verifyData: initialState.verifyData,
      };
    },
  },
});

const { actions, reducer } = createdSlice;

export const {
  verifySms,
  verifySmsSuccess,
  verifySmsError,
  verifySmsReset,
} = actions;

export default reducer;
