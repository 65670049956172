import { takeEvery, put, call } from "redux-saga/effects";
import authService from "../../services/auth";
import * as verifySmsActions from "../reducers/verifySms";
import * as registerActions from "../reducers/register";
import * as loginActions from "../reducers/login";

export function* register({ payload }) {

  try {
    const response = yield call(authService.registerUser, payload);
    if (response) {
      yield put({ type: registerActions.registerUserSuccess.type, response });
    } else {
      yield put({ type: registerActions.registerUserError.type, response });
    }
  } catch (error) {
    yield put({
      type: registerActions.registerUserError.type,
      response: error.response,
    });
  }
}

export function* verifySms({ payload }) {
  try {
    const response = yield call(authService.verifySms, payload);
    if (response) {
      yield put({ type: verifySmsActions.verifySmsSuccess.type, response });
    } else {
      yield put({ type: verifySmsActions.verifySmsError.type, response });
    }
  } catch (error) {
    yield put({
      type: verifySmsActions.verifySmsError.type,
      response: error.response,
    });
  }
}

export function* login({ payload }) {
  try {
      const response = yield call(authService.loginUser, payload);
      if (response) {
          yield put({ type: loginActions.loginUserSuccess.type, response });
      } else {
          yield put({ type: loginActions.loginUserError.type, response });
      }
  } catch (error) {
      yield put({ type: loginActions.loginUserError.type, response: error.response });
  }
}

export function* resetPassword({ payload }) {
  try {
      const response = yield call(authService.resetPassword, payload);
      if (response) {
          yield put({ type: loginActions.resetPasswordSuccess.type, response });
      } else {
          yield put({ type: loginActions.resetPasswordError.type, response });
      }
  } catch (error) {
      yield put({ type: loginActions.resetPasswordError.type, response: error.response });
  }
}

export function* reSendOTP({ payload }) {
  try {
      const response = yield call(authService.resendOTP, payload);
      if (response) {
          yield put({ type: loginActions.reSendOTPSuccess.type, response });
      } else {
          yield put({ type: loginActions.reSendOTPError.type, response });
      }
  } catch (error) {
      yield put({ type: loginActions.reSendOTPError.type, response: error.response });
  }
}

export default function* watchers() {
  yield takeEvery(registerActions.registerUser.type, register);
  yield takeEvery(verifySmsActions.verifySms.type, verifySms);
  yield takeEvery(loginActions.loginUser.type, login);
  yield takeEvery(loginActions.resetPassword.type, resetPassword);
  yield takeEvery(loginActions.reSendOTP.type, reSendOTP);
}
