import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';


// services
import forgotPasswordService from '../../services/forgotPassword';

// reducers
import * as actions from '../reducers/forgotPassword';

export function* requestPasswordReset({ payload }) {
    try {
        const response = yield call(forgotPasswordService.requestReset, payload);
        if (response) {
            yield put({ type: actions.passwordResetEmailSent.type, response });
        } else {
            yield put({ type: actions.passwordResetEmailError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.passwordResetEmailError.type, response: error.response });
    }
}

export function* requestPasswordResetOtp({ payload }) {
    try {
        const response = yield call(forgotPasswordService.requestResetOtp, payload);
        if (response) {
            yield put({ type: actions.passwordResetRequestOTPSuccess.type, response });
        } else {
            yield put({ type: actions.passwordResetRequestOTPError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.passwordResetRequestOTPError.type, response: error.response });
    }
}

export function* verifyToken({ payload }) {
    try {
        const response = yield call(forgotPasswordService.verifyToken, payload);
        if (response) {
            yield put({ type: actions.passwordResetTokenValid.type, response });
        } else {
            yield put({ type: actions.passwordReserTokenInvalid.type, response });
        }
    } catch (error) {
        yield put({ type: actions.passwordReserTokenInvalid.type, response: error.response });
    }
}


export function* verifyOtp({ payload }) {
    try {
        const response = yield call(forgotPasswordService.verifyOtp, payload);
        if (response) {
            yield put({ type: actions.passwordResetVerifyOTPSuccess.type, response });
        } else {
            yield put({ type: actions.passwordResetVerifytOTPError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.passwordResetVerifytOTPError.type, response: error.response });
    }
}

export function* verifyHealthId({ payload }) {
    try {
        const response = yield call(forgotPasswordService.verifyHealthId, payload);
        if (response) {
            yield put({ type: actions.passwordResetVerifyHealthIdSuccess.type, response });
            if (response.data.body.emailAvailable) {
                yield put({ type: actions.passwordResetEmailAvailable.type, response });

            }
        } else {
            yield put({ type: actions.passwordResetVerifytHealthIdError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.passwordResetVerifytHealthIdError.type, response: error.response });
    }
}


export function* resetPassword({ payload }) {
    try {
        const response = yield call(forgotPasswordService.resetPwd, payload);
        if (response) {
            yield put({ type: actions.passwordResetSuccess.type, response });
        } else {
            yield put({ type: actions.passwordResetError.type, response });
        }
    } catch (error) {
        yield put({ type: actions.passwordResetError.type, response: error.response });
    }
}


export default function* watchers() {
    yield takeLatest(actions.passwordResetLoading.type, resetPassword);
    yield takeLatest(actions.passwordResetTokenLoading.type, verifyToken);
    yield takeLatest(actions.passwordResetEmailLoading.type, requestPasswordReset);
    yield takeLatest(actions.passwordResetRequestOTPLoading.type, requestPasswordResetOtp);
    yield takeLatest(actions.passwordResetVerifyOTPLoading.type, verifyOtp);
    yield takeLatest(actions.passwordResetVerifyHealthIDLoading.type, verifyHealthId);


}
