import axios from '../helpers/api';

const httpRequests = {
    fetchAccountData: data => axios.get('/users/signed-in', data),
    updateAccountData: data => axios.put('/users/signed-in', data),
    uploadProfilePicture: data => axios.post('/users/signed-in/profile-image', data),
    vaccinatedData: data => axios.post('/users/get-vaccinated', data),
};

export default httpRequests;
